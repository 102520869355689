import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <footer>
            <div className="">
                2023 © Osiris. All rights reserved.
            </div>
        </footer>
    );
}

export default Header;