import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Header = () => {
    const [width, setWidth] = useState(window.innerWidth);
    
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <header>
            <Link to="/">
                <img loading="lazy" src={require("../../assets/logo9.svg").default} alt="logo" className="logo" />
            </Link>

            {/* <div className="searchbar">
                <input type="text" placeholder="Search documentation..." />

                <div className="button">
                    WRITE HERE
                </div>
            </div> */}
        </header>
    );
}

export default Header;