import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Index = ({children}) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    
    return (
        <div className="layout">
            {children}
        </div>
    )
};

export default Index;