import React, { useEffect, Suspense, lazy, useState } from "react";
import { Route, Switch } from "react-router-dom";

import { Header, Footer, } from "../common";
import { Layout } from "../common/utils";

import Article from "./Article";

const Application = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    return (
        <>

            <Layout>
                <Header />
                <div className={isMobile ? "d-flex flex-column justify-content-center w-100" : "d-flex flex-row justify-content-center"}>
                    {/* <Menu />

                    <Switch>
                        <Route exact path="/:page/:subpage">
                            <Article />
                        </Route>
                        <Route exact path="/:page">
                            <Article />
                        </Route>
                        <Route exact path="/">
                            <Article />
                        </Route>
                    </Switch> */}
                    <Switch>
                        <Route exact path="/">
                            <div className="d-flex flex-row justify-content-center align-items-center title">
                                <h1 className="text-center ">
                                    Light paper coming soon...
                                </h1>
                            </div>
                        </Route>
                    </Switch>
                </div>
                <Footer />
            </Layout>

        </>
    );
};

export default Application;